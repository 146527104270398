import { useContext } from 'react';
import './contact-us.css';
import { LanguageContext } from '../../../context/language-context';

export function ContactUs(): JSX.Element {
    const { t } = useContext(LanguageContext);

    return (
        <div id='contact-us' className="contact-us">
            <div className="contact-data">
                <h1>
                    { t('contact_us_header') }
                </h1>

                <div className='contact-us-ways'>
                    <div>
                        <a href={`tel:${ t('contact_us_phone')?.replaceAll(' ', '') }`}>
                            <img src='contact-us/phone.png'/>
                            { t('contact_us_phone') }
                        </a>
                    </div>
                    <div>
                        <a href={`mailTo:${t('contact_us_mail')}`}>
                            <img src='contact-us/mail.png'/>
                            { t('contact_us_mail') }
                        </a>
                    </div>
                </div>
            </div>
            {/* <div className="contact-form">
                <form>
                    asdasdasd
                </form>
            </div> */}
        </div>
    );
}