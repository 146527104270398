import { coachList } from '../../types/coach/coach-list';
import { CoachComponent } from './coach/coach-component';
import './coaches-route.css';

export function CoachesRoute() {
    return (
        <div className='coaches-route'>
            <div className='route-header'>
                <h1>Our coaches</h1>
                <a href='../'>
                    <img src={'/logo.png'} />
                </a>
            </div>

            {coachList.map((coach) => (
                <CoachComponent coach={coach} />
            ))}
        </div>
    );
}