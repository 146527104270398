import { useContext } from 'react';
import './how-we-work.css';
import { LanguageContext } from '../../../context/language-context';

export function HowWeWork(): JSX.Element {
    const { t } = useContext(LanguageContext);

    return (
        <div className='how-we-work dual-panel'>
            <div className='how-we-work-banner'>
                <h1>
                    { t('how_we_work_header') }
                </h1>
            </div>

            <ul className='how-we-work-list'>
                <li>
                    <img src='/how-we-work/meeting.png'/>
                    { t('how_we_work_meeting') }
                </li>

                <li>
                    <img src='/how-we-work/offer.png'/>
                    { t('how_we_work_offer') }
                </li>

                <li>
                    <img src='/how-we-work/contract.png'/>
                    { t('how_we_work_contract') }
                </li>

                <li>
                    <img src='/how-we-work/start.png'/>
                    { t('how_we_work_start') }
                </li>

                <li>
                    <img src='/how-we-work/still-here.png'/>
                    { t('how_we_work_still_here') }
                </li>
            </ul>
        </div>
    );
}