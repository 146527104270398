import { Player } from "../../types/player/player";
import {
    karelKubat, marekHovorka, martinKadlec, pavelKordule, igorSaprykin, yuriZholobov,
    shaneHeffernan, kyleHeffernan, cedricDelamarche, davidHomer, bryanMacgregor,
    denisKorovayev, kirillVlasov, ossianEklund, dmitriySelyutin, danilKudashev,
    ivanLapshin, denissBaskatovs, tomasSlama, robertPukalovic, ivanRychlik,
    kamilMingazov, jiriTupy, aleksandrBaburins, michalFoltyn
} from "../../types/player/player-constants";

export const playerList: Player[] = [
    karelKubat,
    marekHovorka,
    martinKadlec,
    pavelKordule,
    igorSaprykin,
    yuriZholobov,
    shaneHeffernan,
    kyleHeffernan,
    cedricDelamarche,
    davidHomer,
    michalFoltyn,
    bryanMacgregor,
    denisKorovayev,
    kirillVlasov,
    ossianEklund,
    dmitriySelyutin,
    danilKudashev,
    ivanLapshin,
    denissBaskatovs,
    // mikulasJencovsky,
    // vojtechHambalek,
    tomasSlama,
    robertPukalovic,
    ivanRychlik,
    kamilMingazov,
    jiriTupy,
    aleksandrBaburins,
    // jaroslavPrchal,
]