import { Player, StickDirection } from './player';
import { be, by, ca, cn, cz, de, dk, fi, fr, hu, kz, lv, nl, no, pl, ro, ru, se, si, sk, ua, us } from '../country/country-constants';

export const karelKubat: Player = {
    name: 'Karel Kubát',
    pictureUrl: 'karel-kubat',
    bornIn: 1988,
    height: 183,
    weight: 75,
    stick: StickDirection.LEFT,
    countries: [ cz, ro ],
    description: (
        <>
            One of the most successful, constructive defenders I had an opportunity to work with is Karel, who is not only a great strategic player, 
            but also mentally and physically strong team supporter. 
            Coaches rely on him when the situation on ice gets serious.
            In 2015/2016 Karel was a winner of Czech Extra league in the club 
            HC Verva Litvínov. He got a valuable experience in the KHL
            club Admiral Vladivostok.<br/>
            In 2022/2023 from the position of a defender he shooted 12 goals in 
            all of the games, which made him the best goal defender-scorer. He 
            is currently active in Erste league where he - again - belongs to the 
            best defenders in terms of shooted goals, with assistance of other 
            players.<br/>
            With the club Csíkszereda he became a winner of the all league 
            in 2021/2022 and he was also a winner of Erste league 
            and Romanian league.
        </>
    ),
};

export const karelKubatCancel: Player = {
        name: 'Karel Kubát (cancel contract)',
        pictureUrl: 'karel-kubat',
    };

export const marekHovorka: Player = {
        name: 'Marek Hovorka',
        pictureUrl: 'marek-hovorka',
        bornIn: 1984,
        height: 177,
        weight: 82,
        stick: StickDirection.RIGHT,
        countries: [ cz ],
        description: (
            <>
                Marek is one of the fastest skaters I had an opportunity to cooperate 
                with. We can definitely count to his greatest achievements taking 
                a part in the Olympic games in 2018 in Korea. The highest Russian 
                competition KHL, Czech and Slovac extraleague sound more than 
                familiar to him as he has been actively playing in all of them for 
                many years. His physical condition is admirable and he belongs to 
                the top goal scorers.<br/>
                In we have a look to history, he was playing in 2011/2012 in HC 
                Košice. Recently he has been in 2017/2018 member of the Slovak 
                national team during the Olympic games, 2021/2022 n. 1 Leader 
                by points in all league in Poland and 2022/2023 in Slovak Extra 
                League he became a champion in HC Košice team.
            </>
        ),
    }

export const martinKadlec: Player = {
        name: 'Martin Kadlec',
        pictureUrl: 'martin-kadlec',
        bornIn: 1988,
        height: 180,
        weight: 73,
        stick: StickDirection.RIGHT,
        countries: [ cz, by ],
        description: (
            <>
                In the season 2019/2020, with his 32 goals Martin got an award 
                "The best scorer of Chance League". He is a great ice-skater with 
                very skillful hands and brilliant iceskating style.<br/>
                In 2012/2013 in Brest in Belarusian extraleague he was among the 
                "Top 50 best players".<br/>
                During the season 2021/2022 he shot his premier goal in the Czech 
                extraleague, being a proud member of Jaromír Jágr's team "Tygři".
            </>
        ),
    };

export const pavelKordule: Player = {
        name: 'Pavel Kordule',
        pictureUrl: 'pavel-kordule',
        bornIn: 1999,
        height: 185,
        weight: 65,
        stick: StickDirection.LEFT,
        countries: [ cz ],
        description: (
            <>
                Despite being quite a young player, Pavel is a great scorer who can 
                shoot the puck very accurately.<br/>
                In the season 2021/2022 he has often shown himself as the best 
                trainer's (Vladimír Růžička) choice in crises on the ice, supporting 
                the team with an ability to score under pressure anytime the game 
                became more dramatical. He can read the game, he has a great
                precipitation and his physical condition is in a perfect stage.<br/>
                During the season 2022/2023 Pavel scored 25 goals in 41 matches, 
                playing for the team Konkord Litoměřice. 
            </>
        ),
    };

export const igorSaprykin: Player = {
        name: 'Igor Saprykin',
        pictureUrl: 'igor-saprykin',
        bornIn: 1992,
        height: 181,
        weight: 81,
        stick: StickDirection.LEFT,
        countries: [ ru, cn ],
        description: (
            <>
                A skillful goalman who worked as a professional hockey 
                player in KHL and also VHL. Helpful to his team, strong in crises, 
                mentally strong. Fast reactions to the rebounds, perfectly trained 
                and ready for each game.
            </>
        ),
    };

export const yuriZholobov: Player = {
        name: 'Yuri Zholobov',
        pictureUrl: 'yuri-zholobov',
        bornIn: 1998,
        height: 182,
        weight: 74,
        stick: StickDirection.LEFT,
        countries: [ cz, sk ],
        description: (
            <>
                Yuri managed during one hockey season to jump from 
                the Czech 2nd league to the Czech Chance league and just after 
                that he was chosen to the Extra league to the hockey 
                club HC Verva Litvínov.<br/>
                He can easily control the puck, he is very precise, 
                fast and focused at the same time.
            </>
        ),
    };

export const shaneHeffernan: Player = {
        name: 'Shane Heffernan',
        pictureUrl: 'shane-heffernan',
        bornIn: 1987,
        height: 181,
        weight: 82,
        stick: StickDirection.LEFT,
        countries: [ ca, us, no, de, lv, sk, by, se, pl, ro, si ],
        description: (
            <>
                Shane can be a very good ice skater, who puts a lot of energy 
                to a game, familiar with the the situations "one to one".
                Using his briliant shooting a lot he can pass the puck to another 
                team member in order to create a dynamic game.<br/>
                He can be proud of his physical condition and just as
                his brother and he was professionally active in 10 countries.
            </>
        ),
    };

export const kyleHeffernan: Player = {
        name: 'Kyle Heffernan',
        pictureUrl: 'kyle-heffernan',
        bornIn: 1988,
        height: 191,
        weight: 100,
        stick: StickDirection.RIGHT,
        countries: [ ca, us, cz, nl, pl, se, ro, dk, kz, be, cn, ua, fr ],
        description: (
            <>
                Kyle is very strong and successful in holding the puck, he can hide 
                him under the hockey stick perfectly so that nobody can take 
                it over easily. He is usually very calm finisher, scoring 
                successfully in many tricky situations. His hockey career 
                is built on experiences from various clubs 
                in 10 different countries.
            </>
        ),
    };

export const cedricDelamarche: Player = {
        name: 'Cedric Delmarche',
        pictureUrl: 'cedric-delmarche',
        bornIn: 1998,
        height: 184,
        weight: 90,
        stick: StickDirection.RIGHT,
        countries: [ be, se, cz ],
        description: (
            <>
                Cedric is a D-man who can play smart, using his body well, 
                especially close to the board. Cedric played in the Junior
                league in Sweden.<br/>
                In the category Man league in Czechia he played in Chance Liga for 
                two seasons and he was also active in 2nd league.
                His characteristic features make him a great player for any 
                team.<br/>
                In the season 2022/2023 became Champion of Beneliga with the 
                team of Bulldogs Liege. 
                Cedric also played in the World Championship in the season 
                2021/2022 for Belgium national.
            </>
        ),
    };

export const davidHomer: Player = {
        name: 'David Homér',
        pictureUrl: 'david-homer',
        bornIn: 1993,
        height: 185,
        weight: 84,
        stick: StickDirection.LEFT,
        countries: [ cz ],
        description: (
            <>
                David is a technical scorer, typical for a good work with 
                the puck, who got the most important hockey experiences 
                when playing the junior Extra league in Russia 
                and Belarus, 2nd highest league in Denmark 
                and more than 150 starts
                in Chance league.
            </>
        ),
    };

export const michalFoltyn: Player = {
        name: 'Michal Foltýn',
        pictureUrl: 'michal-foltyn',
        bornIn: 1994,
        height: 185,
        weight: 80,
        stick: StickDirection.LEFT,
        countries: [ cz, sk, ua ],
        description: (
            <>
                With his experience from Extra league, Chance League and 2nd 
                league in the Czech republic Michal is a professional hockey player 
                who knows the world of ice hockey "criss-cross". Having such a type 
                of a player is a very big advantage for a team, because he can 
                understand other players and also assess their next steps.<br/>
                He loves the "hard game" and for his muscles and no fear to go to 
                the ice with full concentration, ready to win, the rivals respect him. 
                Skillful ice-skater, fast and strong, good work with a puck.
            </>
        ),
    };

export const bryanMacgregor: Player = {
        name: 'Bryan MacGregor',
        pictureUrl: 'bryan-macgregor',
        bornIn: 1984,
        height: 184,
        weight: 94,
        stick: StickDirection.RIGHT,
        countries: [ ca, us, fi, cz, pl, kz, dk, hu, ro ],
        description: (
            <>
                In his first season of the Extra league in Třinec in 2010/2011, 
                Bryan engaged everyone's attention, because he turned out
                to be a very good skater and using all of his skills 
                he substantially contributed to the fact that the 
                team got the Extra league title.
            </>
        ),
    };

export const denisKorovayev: Player = {
        name: 'Denis Korovayev',
        pictureUrl: 'denis-korovayev',
        bornIn: 1990,
        height: 183,
        weight: 79,
        stick: StickDirection.LEFT,
        countries: [ nl, kz, ua ],
        description: (
            <>
                Denis is a very experienced goalie with a great attitude 
                and strong instinct to influence the game in order to win.
                Denis won an Asian Cup in the season 2018/19 with the 
                team of Sakhalin. Denis has also very good statistics 
                from Kazakhstan and Poland League.
            </>
        ),
    };

export const kirillVlasov: Player = {
        name: 'Kirill Vlasov',
        pictureUrl: 'kirill-vlasov',
        bornIn: 1994,
        height: 173,
        weight: 75,
        stick: StickDirection.LEFT,
        countries: [ nl, cz, kz ],
        description: (
            <>
                Kirill is a multitalented, universal professional hockey player, 
                he can be used as a forward just as defender. 
                The way he reads the game and the way he moves make 
                him a supportive member of any team, who passes 
                the puck when it is the right time. Kirill made a good 
                name as an important player in the Kazakhstan League.
            </>
        ),
    };

export const ossianEklund: Player = {
        name: 'Ossian Eklund',
        pictureUrl: 'ossian-eklund',
        bornIn: 2002,
        height: 186,
        weight: 87,
        stick: StickDirection.RIGHT,
        countries: [ cz, fi, se ],
        description: (
            <>
                Ossian is the type of a player who likes to be a very active part of the 
                game, having a constant control of the puck location.<br/>
                He is a very good ice skater with a sense of cooperation, knowing 
                exactly when it is the time to send the puck to other team member. 
                Playing currently as one of my youngest hockey players in Chance 
                League he is working hard on his progress with great ambitions to 
                get to the Extra league.
            </>
        ),
    };

export const dmitriySelyutin: Player = {
        name: 'Dmitriy Selyutin',
        pictureUrl: 'dmitriy-selyutin',
        bornIn: 1997,
        height: 186,
        weight: 92,
        stick: StickDirection.RIGHT,
        countries: [ lv, ru, ca, kz ],
        description: (
            <>
                A great work with the puck, good skating skills and well
                trained body are typical for this player. His experiences 
                are formed by the Extra league in Latvia, VHL in Russia, 
                Extra league in Kazakhstan.<br/>
                In the season 2021/2022 he scored in 32 games 
                all together 43 points.
            </>
        ),
    };

export const danilKudashev: Player = {
        name: 'Danil Kudashev',
        pictureUrl: 'danil-kudashev',
        bornIn: 1996,
        height: 186,
        weight: 79,
        stick: StickDirection.LEFT,
        countries: [ se, ru ],
        description: (
            <>
                An athletic body shape of this goalie makes him be fast when 
                catching goals, just as his strategy to asses where his competitor 
                wants to shoot the puck. Experience from Sweden, KHL, VHL 
                and MHL speak for him a lot. In 2019/2020 he got into 
                Top 10 players of VHL goalies (evaluated by the percentage 
                of successfully caught goals).
            </>
        ),
    };

export const ivanLapshin: Player = {
        name: 'Ivan Lapshin',
        pictureUrl: 'ivan-lapshin',
        bornIn: 1999,
        height: 182,
        weight: 76,
        stick: StickDirection.LEFT,
        countries: [ cz ],
        description: (
            <>
                This 23-year-old player managed to shoot 11 goals in 48 games. 
                He got a substantial experience in VHL and MHL, where 
                he belonged to leaders of his team. He can shoot a goal, 
                beat a opponent player in the situation "one to one". 
                By working hard on his own progress we should definitely 
                not forget to mention the fact he is having a great potential 
                to go on with his professional career.
            </>
        ),
    };

export const denissBaskatovs: Player = {
        name: 'Deniss Baskatovs',
        pictureUrl: 'deniss-baskatovs',
        bornIn: 1993,
        height: 185,
        weight: 90,
        stick: StickDirection.LEFT,
        countries: [ lv, nl, by, ru, be, fr ],
        description: (
            <>
                Intelligence and strategic point of view combined with 
                good eyes and quick hands make him a great player, skillful 
                with the puck. Belgium, France, UK, Latvia, Belarus - all 
                of these countries helped him form his hockey history.
            </>
        ),
    };

// export const mikulasJencovsky: Player = {
//         name: 'Mikuláš Jenčovský',
//         pictureUrl: 'mikulas-jencovsky',
//         bornIn: 2003,
//         height: 181,
//         weight: 76,
//         stick: StickDirection.LEFT,
//         countries: [ cz ],
//         description: (
//             <>
//                 For those who would like to have a goal oriented forward with high 
//                 ambitions to support the team with as many goals as possible, 
//                 Mikuláš is one of the best candidates for this task. 
//                 Junior extra league (also the club HC Bílí Tygři Liberec) 
//                 can be proud of his skilful maneuvers and accuracy 
//                 combined with speed.
//             </>
//         ),
//     };

// export const vojtechHambalek: Player = {
//         name: 'Vojtěch Hambálek',
//         pictureUrl: 'vojtech-hambalek',
//         bornIn: 2005,
//         height: 194,
//         weight: 92,
//         stick: StickDirection.RIGHT,
//         countries: [ cz ],
//         description: (
//             <>
//                 Vojtěch as has a professional hockey player a very 
//                 successful career as an experienced and accurate
//                 goalie behind himself. He is being welcome by every team 
//                 of Junior Extra League not just for his skills, but also for his friendly 
//                 character and good sense of humor. He is currently being 
//                 professionally located in HC Bílí Tygři Liberec.
//             </>
//         ),
//     };

export const tomasSlama: Player = {
        name: 'Tomáš Sláma',
        pictureUrl: 'tomas-slama',
        bornIn: 1983,
        height: 181,
        weight: 88,
        stick: StickDirection.LEFT,
        countries: [ cz ],
        description: (
            <>
                In junior Extra league in Karlovy Vary he belonged 
                to the top players. He got a renomé when playing in France 
                in the club in Toulon, division 2. Fans like him for the way 
                he perceives the game and the movements 
                he makes on ice. 
            </>
        ),
    };

export const robertPukalovic: Player = {
        name: 'Róbert Pukalovič',
        pictureUrl: 'robert-pukalovic',
        bornIn: 1984,
        height: 181,
        weight: 73,
        stick: StickDirection.RIGHT,
        countries: [ cz, sk ],
        description: (
            <>
                He is the son of a Slovak legend Robert Pukalovič (senior) 
                who was professionally active in Belarus, Czech 
                and Slovak Republic. He regrettably had to finish his career 
                soon for a serious illness and was medically forbidden 
                to get back to the field of professional sport.
            </>
        ),
    };

export const ivanRychlik: Player = {
        name: 'Ivan Rybchik',
        pictureUrl: 'ivan-rybchik',
        bornIn: 1994,
        height: 187,
        weight: 86,
        stick: StickDirection.RIGHT,
        countries: [ by, kz, lv, ua, pl, hu, fi ],
        description: (
            <>
                Ivan is very talented and strong right hand forward, 
                who has experience from Latvia League, Kazakh League, 
                Ukraine League, Poland League and also Erste League. 
                For him is typical a strong shooting instinct, he can also 
                be a goal scorer.
            </>
        ),
    };

export const kamilMingazov: Player = {
        name: 'Kamil Mingazov',
        pictureUrl: 'kamil-mingazov',
        bornIn: 1996,
        height: 178,
        weight: 79,
        stick: StickDirection.RIGHT,
        countries: [ nl, by, hu ],
        description: (
            <>
                Kamil is a very offensive defenceman, great skater and strategist, 
                often being chosen to play in the power play. 
                Kamil made a huge progress during the season 2022/2023, 
                in the Erste Liga was one of the top D-man's by points.
            </>
        ),
    };

export const jiriTupy: Player = {
        name: 'Jiří Tupý',
        pictureUrl: 'jiri-tupy',
        bornIn: 1996,
        height: 185,
        weight: 77,
        stick: StickDirection.LEFT,
        countries: [ cz ],
        description: (
            <>
                Jiří is a very fast skater who played more than 90 games 
                in Czechia in Chance Liga and also played in the league Suomi 
                Saarja in Finland. He can best show his skills especially in
                those situations which require strong focus and fast reactions.
            </>
        ),
    };

export const aleksandrBaburins: Player = {
        name: 'Aleksandr Baburins',
        pictureUrl: 'aleksandr-baburins',
        bornIn: 1988,
        height: 181,
        weight: 82,
        stick: StickDirection.RIGHT,
        countries: [ cz, lv, se, by ],
        description: (
            <>
                Aleksandr is very strong skater, smooth hands, can be used also 
                like an player-coach. He has experience from Sweden junior league, 
                man leagues: Czech Republice,Latvia,
                Belarus elite League.
            </>
        ),
    };

// export const jaroslavPrchal: Player = {
//         name: 'Jaroslav Prchal',
//         pictureUrl: 'jaroslav-prchal',
//         bornIn: 2008,
//         stick: StickDirection.RIGHT,
//         countries: [ cz ],
//         description: (
//             <>
//                 Jaroslav is a young and perspective hockey player, working hard 
//                 every single day on his progress. Skillful with the puck, reading 
//                 the game, a good friend to his team members, loyal to a coach, 
//                 respecting the authorities' orders.
//             </>
//         ),
//     };

export const michalSzabo: Player = {
    name: 'Michal Szabo',
    pictureUrl: 'michal-szabo',
}

export const vladVrtek: Player = {
    name: 'Vlad Vrtek',
    pictureUrl: 'vlad-vrtek',
}

export const artemSursov: Player = {
    name: 'Artem Sursov',
    pictureUrl: 'artem-sursov',
}

export const kamilCharousek: Player = {
    name: 'Kamil Charousek',
    pictureUrl: 'kamil-charousek',
}