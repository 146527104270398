import { Country } from "../country/country";

export enum StickDirection {
    LEFT = 'left',
    RIGHT = 'right',
}

export interface Player {
    name: string,
    pictureUrl: string,
    bornIn?: number,
    height?: number,
    weight?: number,
    stick?: StickDirection,
    countries?: Country[],
    description?: JSX.Element,
}