import { useContext } from "react";
import { AboutUsPerson, michalFoltyn, petrFormanek } from "./about-us-person";
import './about-us.css';
import { LanguageContext } from "../../../context/language-context";

export function AboutUs(): JSX.Element {
    const { t } = useContext(LanguageContext);

    return (
        <div id='about-us' className="about-us">
            <div className="about-us-text">
                <h1>
                    { t('about_us_header') }
                </h1>
            </div>

            <div className="about-us-1">
                <AboutUsPerson person={petrFormanek}/>
            </div>
            <div className="about-us-2">
                <AboutUsPerson person={michalFoltyn}/>
            </div>
        </div>
    );
}