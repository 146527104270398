import './main.css';
import { AboutUs } from "./about-us/about-us";
import { AgentsAndConsultants } from "./agents-and-consultants/agents-and-consultants";
import { Banner } from "./banner/banner";
import { CallUs } from "./call-us/call-us";
import { Clients } from "./clients/clients";
import { Coaches } from "./coaches/coaches";
import { ContactUs } from "./contact-us/contact-us";
import { Countries } from "./countries/countries";
import { Footer } from "./footer";
import { HowWeWork } from "./how-we-work/how-we-work";
import { Menu } from "./menu/menu";
import { Partners } from "./partners";
import { Services } from "./services/services";
import { useLanguage } from '../../hooks/use-language';
import { LanguageContext } from '../../context/language-context';

export function Main() {
  const languageContextValue = useLanguage({ section: 'main' });

  return (
    <LanguageContext.Provider value={languageContextValue}>
      <div className='main'>
        <Menu/>
        <Banner/>
        <Services/>
        <HowWeWork/>
        <Clients/>
        <Countries/>
        <Coaches/>
        <AboutUs/>
        <ContactUs/>
        <Partners/>
        <AgentsAndConsultants/>
        <CallUs/>
        <Footer/>
      </div>
    </LanguageContext.Provider>
  );
}
