import { Coach } from "../../../../types/coach/coach";
import { Player } from "../../../../types/player/player";
import './client-list-component.css';

interface ClientListComponentInput {
    clients: (Player | Coach)[]
}

export function ClientListComponent({clients}: ClientListComponentInput): JSX.Element {
    if (clients.length === 0) {
        return <></>
    }

    return (
        <div className="client-list-main">
            <h3 className="client-list-header">
                Clients played in this league:
            </h3>

            <div className="client-list">
                {clients.map(c => (
                    <div className="client-list-item">
                        <img className="client-list-item-picture" src={`../clients/${c.pictureUrl}.png`}/>

                        <span>{c.name}</span>
                    </div>
                ))}
            </div>
        </div>

    );
}