import { useContext } from 'react';
import { Country } from '../../../types/country/country';
import { be, cz, fr, lv, sk } from '../../../types/country/country-constants';
import './countries.css';
import { LanguageContext } from '../../../context/language-context';

export function Countries(): JSX.Element {
    const { t } = useContext(LanguageContext);

    const countries: Country[] = [
        cz, lv, sk, be, fr,
    ];

    return (
        <div className='list-main'>
            <h1 className='list-header-right'>
                { t('countries_header') }
            </h1>

            <div className='list-body'>
                {countries.map((c) => {
                    return (
                        <div className='list-item' key={c.name}>
                            <img src={`countries/${c.url}.png`}/>
                            <span>{c.name}</span>
                        </div>
                    );
                })}
            </div>

            <a href='./countries' className='list-footer'>
                { t('countries_show_more') }
            </a>
        </div>
    );
}