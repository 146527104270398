import { useContext } from 'react';
import { Player } from '../../../types/player/player';
import { igorSaprykin, karelKubat, marekHovorka, martinKadlec, pavelKordule } from '../../../types/player/player-constants';
import './clients.css';
import { LanguageContext } from '../../../context/language-context';

export function Clients(): JSX.Element {
    const { t } = useContext(LanguageContext);

    const playerList: Player[] = [
        karelKubat,
        marekHovorka,
        martinKadlec,
        pavelKordule,
        igorSaprykin,
    ];

    return (
        <div id='clients' className='list-main'>
            <h1 className='list-header-left'>
                { t('clients_header') }
            </h1>

            <div className='list-body'>
                {playerList.slice(0, 5).map((p) => {
                    return (
                        <div className='list-item' key={p.name}>
                            <img src={`clients/${p.pictureUrl}.png`}/>
                            <span>{p.name}</span>
                        </div>
                    );
                })}
            </div>

            <a href='./clients' className='list-footer'>
                { t('clients_read_more') }
            </a>
        </div>
    );
}