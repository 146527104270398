import { adamVarba, alesTotter, alexMikhenotok, valeryKulibaba } from "../coach/coach-constants";
import { artemSursov, bryanMacgregor, cedricDelamarche, danilKudashev, davidHomer, denisKorovayev, denissBaskatovs, dmitriySelyutin, igorSaprykin, ivanLapshin, ivanRychlik, jiriTupy, kamilCharousek, kamilMingazov, karelKubat, karelKubatCancel, kirillVlasov, kyleHeffernan, marekHovorka, martinKadlec, michalFoltyn, michalSzabo, ossianEklund, pavelKordule, robertPukalovic, shaneHeffernan, tomasSlama, vladVrtek, yuriZholobov } from "../player/player-constants";
import { League } from "./league";

export const czechTipsportExtraliga: League = {
    name: 'Czech Tipsort extraliga',
    logo: "",
    clients: [ martinKadlec, karelKubat, marekHovorka, alesTotter, yuriZholobov, bryanMacgregor, michalFoltyn, pavelKordule ],
    description: (<>
        <p>
            <b>(elite level)<br/>
            Includes 14 teams</b>
        </p>

        <p>
            80% Czech players (325)<br/>
            35 Slovak players<br/>
            11 Canadian players<br/>
            11 Latvian players<br/>
            7 Finnish players<br/>
            19 players are from other nationalities
        </p>

        <p style={{ color: 'var(--main-blue)' }}>
            <b>Salary 40.000 EUR - 120.000 EUR / season)</b>
        </p>
    </>)
}

export const czechiaChanceLiga: League = {
    name: 'Czechia Chance Liga',
    logo: '',
    clients: [ ossianEklund, davidHomer, adamVarba ],
    description: (<>
        <p>
            <b>(Czechia 3rd level)<br/>
            Includes 27 teams</b>
        </p>

        <p>
            80% Czech players (325)<br/>
            35 Slovak players<br/>
            11 Canadian players<br/>
            11 Latvian players<br/>
            7 Finnish players<br/>
            19 players are from other nationalities
        </p>

        <p style={{ color: 'var(--main-blue)' }}>
            <b>Salary 12.000 EUR - 40.000 EUR</b>
        </p>
    </>)
}

export const czechiaSecondLiga: League = {
    name: 'Czechia 2. liga',
    logo: '',
    clients: [ ],
    description: (<>
        <p>
            <b>(Czechia 2nd level)<br/>
            Includes 14 teams</b>
        </p>

        <p>
            95% Czech players (857)<br/>
            17 Russian players<br/>
            17 Slovak players<br/>
            4 Ukrainian players<br/>
            13 players are from other nationalities
        </p>
    </>)
}

export const belarusianExtraleague: League = {
    name: 'Belarusian Extraleague',
    logo: '',
    clients: [ alexMikhenotok, martinKadlec, michalSzabo, robertPukalovic, davidHomer ],
    description: (<>
        <p>
            <b>Includes 12 teams</b>
        </p>

        <p>
            77% Belarusian players (270)<br/>
            80 Russian players<br/>
            In season 2022/23 no more other import players
        </p>

        <p style={{ color: 'var(--main-blue)' }}>
            <b>Salary 10.000 EUR - 40.000 EUR</b>
        </p>
    </>)
}

export const beneliga: League = {
    name: 'Beneliga',
    logo: '',
    clients: [ cedricDelamarche, adamVarba ],
    description: (<>
        <p>
            <b>Includes 8 teams:<br/>
            4 Dutch, 4 Belgian</b>
        </p>

        <p>
            46% Belgian players (98)<br/>
            95 Dutch players<br/>
            80 Russian players<br/>
            7 Canadian players<br/>
            7 Finnish players<br/>
            8 players are from other nationalities<br/>
            In season 2022/23 no more other import players
        </p>
    </>)
}

export const metalLigaen: League = {
    name: 'Metal Ligaen',
    logo: '',
    clients: [ davidHomer, karelKubatCancel ],
    description: (<>
        <p>
            <b>Includes 9 teams</b>
        </p>

        <p>
            69% Danish players (163)<br/>
            24 Swedish players<br/>
            17 Canadian players<br/>
            12 American players<br/>
            19 players are from other nationalities
        </p>

        <p style={{ color: 'var(--main-blue)' }}>
            <b>Salary: 20.000 EUR - 50.000 EUR</b>
        </p>
    </>)
}

export const denmarkFirstDivisjon: League = {
    name: 'Denmark 1. Divisjon',
    logo: '',
    clients: [],
    description: (<>
        <p>
            <b>(Denmark 2nd Level)<br/>
            Includes 9 teams</b>
        </p>

        <p>
            90% Danish players (207)<br/>
            6 Latvian players<br/>
            3 Canadian players<br/>
            3 Slovak players
        </p>
    </>)
}

export const smLiiga: League = {
    name: 'SM Liiga',
    logo: '',
    clients: [],
    description: (<>
        <p>
            <b>Includes 15 teams</b>
        </p>

        <p>
            78% Finnish players (345)<br/>
            37 Swedish players<br/>
            22 Canadian players<br/>
            13 Czech players<br/>
            26 players are from other nationalities
        </p>

        <p style={{ color: 'var(--main-blue)' }}>
            <b>Salary: 40.000 EUR - 120.000 EUR</b>
        </p>
    </>)
}

export const mestisLiga: League = {
    name: 'Mestis Liga',
    logo: '',
    clients: [ bryanMacgregor ],
    description: (<>
        <p>
            <b>(Finland 2nd level)<br/>
            Includes 14 teams<br/>
            13 Finnish teams, 1 team from Latvia Zemgale, playing 
            in city Jelgava</b>
        </p>

        <p>
            82% Finnish players (322)<br/>
            47 Latvian players<br/>
            5 Czech players<br/>
            4 Russian players<br/>
            16 players are from other nationalities
        </p>

        <p style={{ color: 'var(--main-blue)' }}>
            <b>Salary 10.000 EUR - 40.000 EUR</b>
        </p>
    </>)
}

export const suomiSaarjaa: League = {
    name: 'Suomi Saarjaa',
    logo: '',
    clients: [ jiriTupy ],
    description: (<>
        <p>
            <b>(Finland 3rd level)<br/>
            Includes 12 teams</b>
        </p>

        <p>
            93% Finnish players (322)<br/>
            8 Estonian players<br/>
            5 Swedish players<br/>
            4 Canadian players<br/>
            5 players are from other nationalities
        </p>
    </>)
}

export const franceMagnusLigueSynerglace: League = {
    name: 'France Magnus Ligue Synerglace',
    logo: '',
    clients: [],
    description: (<>
        <p>
            <b>Includes 12 teams</b>
        </p>

        <p>
            64% French players (208)<br/>
            43 Canadian players<br/>
            25 Finnish players<br/>
            9 American players<br/>
            8 Czech players<br/>
            32 players are from other nationalities
        </p>

        <p style={{ color: 'var(--main-blue)' }}>
            <b>Salary 12.000 EUR - 50.000 EUR / season</b>
        </p>
    </>)
}

export const ffhgFirstDivision: League = {
    name: 'FFHG, Division 1, France',
    logo: '',
    clients: [ kyleHeffernan, shaneHeffernan, denissBaskatovs ],
    description: (<>
        <p>
            <b>(2nd level France)<br/>
            Includes 14 teams</b> 
        </p>

        <p>
            70% French players (249)<br/>
            36 Canadian players<br/>
            18 Czech players<br/>
            15 Slovak players<br/>
            8 American players<br/>
            31 players are from other nationalities
        </p>
    </>)
}

export const ffhgSecondDivision: League = {
    name: 'FFHG, Division 2, France',
    logo: '',
    clients: [ vladVrtek, tomasSlama ],
    description: (<>
        <p>
            <b>Includes 20 teams</b>
        </p>

        <p>
            85% French players (481)<br/>
            18 Canadian players<br/>
            11 Finnish players<br/>
            8 Russian players<br/>
            7 Slovak players<br/>
            38 players are from other nationalities
        </p>
    </>)
}

export const ersteLiga: League = {
    name: 'Erste Liga',
    logo: '',
    clients: [ karelKubat, artemSursov, kamilMingazov, ivanRychlik ],
    description: (<>
        <p>
            <b>Includes 8 teams:<br/>
            7 Hungarian, 3 Romanian</b>
        </p>

        <p>
            58% Hungarian players (183)<br/>
            48 Romanian players<br/>
            24 Finnish players<br/>
            12 Canadian players<br/>
            53 players are from other nationalities
        </p>

        <p style={{ color: 'var(--main-blue)' }}>
            <b>Salary: 15.000 EUR - 50.000 EUR</b>
        </p>
    </>)
}

export const kazakhstanHockeyChampionship: League = {
    name: 'Kazakhstan hockey Championship',
    logo: '',
    clients: [ kirillVlasov, denisKorovayev ],
    description: (<>
        <p>
            <b>Includes 12 teams:<br/>
            11 teams from Kazakstan
            1 team from Uzbekistan, Humo Tashkent</b>
        </p>

        <p>
            55% Kazakh players (201)<br/>
            156 Russian players<br/>
            3 Latvian players<br/>
            2 Belarusian players<br/>
            1 American player<br/>
            1 Uzbek player
        </p>

        <p style={{ color: 'var(--main-blue)' }}>
            <b>Salary: 15.000 EUR - 50.000 EUR</b>
        </p>
    </>)
}

export const optibetHokejaLiga: League = {
    name: 'Optibet Hokeja Liga',
    logo: '',
    clients: [ dmitriySelyutin, valeryKulibaba ],
    description: (<>
        <p>
            <b>Includes 9 teams:<br/>
            6 Latvian, 3 Lithuanian</b>
        </p>

        <p>
            69% Latvian players (212)<br/>
            76 Lithuanian players<br/>
            11 Ukrainian players<br/>
            3 Kazakhstan players<br/>
            3 Slovak players<br/>
            4 players are from other nationalities
        </p>

        <p style={{ color: 'var(--main-blue)' }}>
            <b>Salary: 10.000 EUR - 25.000 EUR / season</b>
        </p>
    </>)
}

export const polskaHokejLiga: League = {
    name: 'Polska Hokej Liga, PHL',
    logo: '',
    clients: [ alexMikhenotok, alesTotter, kamilCharousek ],
    description: (<>
        <p>
            <b>Includes 9 teams</b>
        </p>

        <p>
            60% Polish players (153)<br/>
            23 Finnish players<br/>
            16 Czech players<br/>
            13 Swedish players<br/>
            48 players are from other nationalities
        </p>

        <p style={{ color: 'var(--main-blue)' }}>
            <b>Salary: 10.000 EUR - 50.000 EUR / season</b>
        </p>
    </>)
}

export const polishFirstLiga: League = {
    name: 'Polish 1. liga',
    logo: '',
    clients: [],
    description: (<>
        <p>
            <b>(Poland 2nd level)<br/>
            Includes 14 teams</b>
        </p>

        <p>
            77% Polish players (317)<br/>
            41 Ukrainian players<br/>
            32 Belarusian players<br/>
            7 Russian players<br/>
            13 players are from other nationalities
        </p>
    </>)
}

export const kontinentalHockeyLeague: League = {
    name: 'KHL (Kontinental Hockey League)',
    logo: '',
    clients: [ marekHovorka, karelKubat, igorSaprykin, danilKudashev, ivanLapshin ],
    description: (<>
        <p>
            <b>Includes 22 teams:<br/>
            19 Russian, Barys Astana from Kazakhstan, Dinamo Minsk 
            from Belarus, Kunlun Red Star Beijing from China.</b>
        </p>
        
        <p>
            76% Russian players (519)<br/>
            42 Canadian players<br/>
            39 Belarusian players<br/>
            31 Kazakh players<br/>
            17 Chinese players<br/>
            13 American players<br/>
            8 Slovak players<br/>
            5 Swedish players<br/>
            3 Czech players<br/>
            2 German players<br/>
            2 Latvian players<br/>
            4 players are from other nationalities
        </p>

        <p style={{ color: 'var(--main-blue)' }}>
            <b>Salary: 80.000 EUR - 1,500.000 EUR</b>
        </p>
    </>)
}

export const vyshayaHokkeinayaLiga: League = {
    name: 'VHL Vyshaya Hokkeinaya Liga',
    logo: '',
    clients: [ danilKudashev, ivanLapshin ],
    description: (<>
        <p>
            <b>Includes 26 teams</b>
        </p>

        <p>
            96% Russian players (877)<br/>
            22 Belarusian players<br/>
            10 Kazakh players<br/>
            2 Latvian players<br/>
            3 players are from other nationalities
        </p>

        <p style={{ color: 'var(--main-blue)' }}>
            <b>Salary: 15.000 EUR - 60.000 EUR</b>
        </p>
    </>)
}

export const slovakiaTiposExtraliga: League = {
    name: 'Slovakia Tipos Extraliga',
    logo: '',
    clients: [ marekHovorka ],
    description: (<>
        <p>
            <b>(elite level)<br/>
            Includes 12 teams</b>
        </p>

        <p>
            74% Slovak players (278)<br/>
            33 Canadian players<br/>
            16 American players<br/>
            12 Czech players<br/>
            39 players are from other nationalities
        </p>

        <p style={{ color: 'var(--main-blue)' }}>
            <b>Salary: 15.000 EUR - 60.000 EUR</b>
        </p>
    </>)
}

export const slovakiaSecondLevelSlovenskaHokejovaLiga: League = {
    name: 'Slovakia (2nd level) Slovenska Hokejova Liga',
    logo: '',
    clients: [],
    description: (<>
        <p>
            90% Slovak players (447)<br/>
            15 Czech players<br/>
            14 Russian players<br/>
            6 Finnish players<br/>
            15 players are from other nationalities
        </p>
    </>)
}