import './languages.css';
import { languages } from '../../../../hooks/use-language';
import { useContext } from 'react';
import { LanguageContext } from '../../../../context/language-context';

export function Languages(): JSX.Element {
    const { changeLanguage } = useContext(LanguageContext);

    return (
        <div className='lang-menu'>
            {languages.map((l) => {
                return (
                    <button key={l} onClick={() => changeLanguage(l)}>
                        <img src={`/languages/${l}.png`}/>
                    </button>
                );
            })}
        </div>
    );
}