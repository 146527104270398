import { useCallback, useEffect, useState } from "react";

export const languages = ['en', 'cz', 'ru'];

interface UseLanguageInput {
    section: string,
}

export interface UseLanguageApi {
    isLoaded: boolean,
    language: string,
    t: (key: string) => string,
    changeLanguage: (l: string) => void,
}

export function useLanguage({ section }: UseLanguageInput): UseLanguageApi {
    const [ isLoaded, setIsLoaded ] = useState(false);
    const [ language, setLanguage ] = useState(languages[0]);
    const [ texts, setTexts ] = useState<Map<string, string>>(new Map());

    const changeLanguage = useCallback((l: string) => {
        if (!languages.includes(l)) {
            return;
        }

        setLanguage(l);
    }, []);

    const t = useCallback((key: string): string => {
        return texts.get(key) ?? key;
    }, [texts]);

    useEffect(() => {
        const url = process.env.NODE_ENV === 'development' ? 
            `https://cors-anywhere.herokuapp.com/http://goalhockeyagency.com/api/translations.php?lang=${language}&section=${section}` :
            `/api/translations.php?lang=${language}`;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                setTexts(new Map(
                    data.map((d: any) => [d.key, d.string])
                ));
                setIsLoaded(true);
            });
    }, [language]);

    return {
        isLoaded,
        language,
        t,
        changeLanguage,
    };
}