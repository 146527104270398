import { LeagueGroup } from "../../../types/league/league-groups";
import { LeagueComponent } from "../league-component/league-component";
import './league-group-component.css';

interface LeageGroupComponentInput {
    leagueGroup: LeagueGroup,
}

export function LeageGroupComponent({
    leagueGroup,
}: LeageGroupComponentInput): JSX.Element {
    return (
        <div className="league-group">
            <div className="league-group-header">
                <h2>
                    {leagueGroup.countries.map(c => c.name).join(', ')}
                </h2>

                <div>
                    {leagueGroup.countries.map(c => {
                        return (<img className="league-group-header-flag" src={`../countries/${c.url}.png`}/>)
                    })}
                </div>
            </div>

            <div className="league-group-addendum">
                {leagueGroup.addendum}
            </div>

            {leagueGroup.leagues.map((l) => (
                <LeagueComponent
                    league={l}
                />
            ))}
        </div>
    )
}