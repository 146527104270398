export const cz = {
    name: 'Czech Republic',
    url: 'cz',
};

export const lv = {
    name: 'Latvia',
    url: 'lv',
};

export const sk = {
    name: 'Slovakia',
    url: 'sk',
};

export const be = {
    name: 'Belgium',
    url: 'be',
};

export const fr = {
    name: 'France',
    url: 'fr',
};

export const us = {
    name: 'USA',
    url: 'us',
};

export const ca = {
    name: 'Canada',
    url: 'ca',
};

export const by = {
    name: 'Belarus',
    url: 'by',
};

export const nl = {
    name: 'Netherlands',
    url: 'nl',
};

export const dk = {
    name: 'Denmark',
    url: 'dk',
};

export const fi = {
    name: 'Finland',
    url: 'fi',
};

export const hu = {
    name: 'Hungary',
    url: 'hu',
};

export const ro = {
    name: 'Romania',
    url: 'ro',
};

export const kz = {
    name: 'Kazakhstan',
    url: 'kz',
};

export const pl = {
    name: 'Poland',
    url: 'pl',
};

export const ru = {
    name: 'Russia',
    url: 'ru',
};

export const de = {
    name: 'Germany',
    url: 'de',
};

export const cn = {
    name: 'China',
    url: 'cn',
}

export const no = {
    name: 'Norway',
    url: 'no',
}

export const se = {
    name: 'Sweden',
    url: 'se',
}

export const si = {
    name: 'Slovenia',
    url: 'si',
}

export const ua = {
    name: 'Ukraine',
    url: 'ua',
}

export const lt = {
    name: 'Lithuania',
    url: 'lt',
}