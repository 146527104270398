import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Main } from './components/main/main';
import { CoachesRoute } from './components/coaches-route/coaches-route';
import { ClientsRoute } from './components/clients-route/clients-route';
import { CountriesRoute } from './components/countries-route/countries-route';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: '/coaches',
    element: <CoachesRoute />
  },
  {
    path: '/clients',
    element: <ClientsRoute />
  },
  {
    path: '/countries',
    element: <CountriesRoute />
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
