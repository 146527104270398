import { League } from "../../../types/league/league";
import { ClientListComponent } from "./client-list-component/client-list-component";
import './league-component.css';

interface LeagueComponentInput {
    league: League,
}

export function LeagueComponent({
    league,
}: LeagueComponentInput): JSX.Element {
    return (
        <div className="league">
            <div className="league-data">
                <h3 className="league-data-header">{league.name}</h3>

                <div className="league-data-description">
                    {league.description}
                </div>
            </div>

            <div className="league-clients">
                <ClientListComponent
                    clients={league.clients}
                />
            </div>
        </div>
    );
}