import { Player, StickDirection } from "../../../types/player/player";
import './player-component.css';

interface PlayerComponentInput {
    player: Player;
}

export function PlayerComponent({
    player
}: PlayerComponentInput): JSX.Element {
    return (
        <div className='player'>
            <div className='player-main'>
                <img src={`../clients/${player.pictureUrl}.png`} alt="" />
                <h1>{player.name}</h1>
            </div>
            <div className='player-details'>
                <div className='player-details-header'>
                    <p>Born: {player.bornIn || 'xxxx'}</p>

                    <p className='player-details-header-separator'>|</p>

                    <p>Height/Weight: {player.height || 'xxx'}/{player.weight || 'xx'}</p>

                    <p className='player-details-header-separator'>|</p>

                    <p>Hockey stick: {player.stick === StickDirection.LEFT ? 'Left' : 'Right'}</p>
                </div>

                <div className='player-details-header-countries'>
                    Countries:

                    {player.countries?.map((country) => (
                        <img src={`../countries/${country.url}.png`} alt="" />
                    ))}
                </div>

                <p className='player-description'>
                    {player.description}
                </p>
            </div>
        </div>
    )
}