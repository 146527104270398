import React from 'react';
import { Coach } from '../../../types/coach/coach';
import './coach-component.css';

interface CoachComponentInput {
    coach: Coach;
}

export function CoachComponent({
    coach
}: CoachComponentInput): JSX.Element {
    return (
        <div className='coach'>
            <div className='coach-main'>
                <img src={`../clients/${coach.pictureUrl}.png`} alt="" />
                <h1>{coach.name}</h1>
            </div>
            <div className='coach-details'>
                <div className='coach-details-header'>
                    <p>Born: {coach.bornIn || 'xxxx'}</p>

                    <p className='coach-details-header-separator'>|</p>

                    <div className='coach-details-header-countries'>
                        Countries:

                        {coach.countries.map((country) => (
                            <img src={`../countries/${country.url}.png`} alt="" />
                        ))}
                    </div>
                </div>

                <p className='coach-description'>
                    {coach.description}
                </p>
            </div>
        </div>
    )
}