import { useContext } from 'react';
import './call-us.css';
import { LanguageContext } from '../../../context/language-context';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

export function CallUs(): JSX.Element {
    const { t } = useContext(LanguageContext);

    return (
        <div className="call-us">
            <h1>
                <ReactMarkdown children={t('call_us_header')} />
            </h1>

            <a href={`tel:${t('contact_us_phone').replaceAll(' ', '')}`}>
                <img src='contact-us/phone.png'/>
                { t('contact_us_phone') }
            </a>
        </div>
    );
}