import { useContext } from 'react';
import { coachList } from '../../../types/coach/coach-list';
import './coaches.css';
import { LanguageContext } from '../../../context/language-context';

export function Coaches(): JSX.Element {
    const { t } = useContext(LanguageContext);

    return (
        <div className='list-main'>
            <h1 className='list-header-left'>
                { t('coaches_header') }
            </h1>

            <div className='list-body'>
                {coachList.map((c) => {
                    return (
                        <div className='list-item' key={c.name}>
                            <img src={`clients/${c.pictureUrl}.png`}/>
                            <span>{c.name}</span>
                        </div>
                    );
                })}
            </div>

            <a href='./coaches' className='list-footer'>
                { t('coaches_show_more') }
            </a>
        </div>
    );
}