import { useContext } from 'react';
import './banner.css';
import { LanguageContext } from '../../../context/language-context';

export function Banner(): JSX.Element {
    const { t } = useContext(LanguageContext);

    return (
        <>
            <img className='banner-img' src='/banner.png'/>
            <h1 className='banner-text'>
                { t('banner_text') }
            </h1>
        </>
    );
}