import { useContext } from 'react';
import './agents-and-consultants.css';
import ReactMarkdown from 'react-markdown';
import { LanguageContext } from '../../../context/language-context';

export function AgentsAndConsultants(): JSX.Element {
    const { t } = useContext(LanguageContext);

    return (
        <div className="agents dual-panel">
            <div className='agents-header'>
                <h1>{ t('agents_header') }</h1>
            </div>

            <div className='agents-text'>
                <ReactMarkdown children={t('agents_text_1')}/>
                <ReactMarkdown children={t('agents_text_2')}/>
                <ReactMarkdown children={t('agents_text_3')}/>
            </div>
        </div>
    );
}