import { useContext } from 'react';
import './services.css';
import { LanguageContext } from '../../../context/language-context';

export function Services(): JSX.Element {
    const { t } = useContext(LanguageContext);

    return (
        <div id='services' className='services dual-panel'>
            <div className='services-banner'>
                <h1>
                    { t('services_header') }
                </h1>
            </div>

            <div className='services-text'>
                <p>
                    { t('services_text_1') }
                </p>

                <p>
                    { t('services_text_2') }:
                </p>

                <ul>
                    <li>{ t('services_text_3_item_1') }</li>
                    <li>{ t('services_text_3_item_2') }</li>
                    <li>{ t('services_text_3_item_3') }</li>
                </ul>

                <p>
                    { t('services_text_4') }
                </p>
            </div>
        </div>
    );
}