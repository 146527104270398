import './countries-route.css';
import { leagueGroups } from "../../types/league/league-groups";
import { LeageGroupComponent } from "./league-group-component/league-group-component";

export function CountriesRoute(): JSX.Element {
    return (
        <div className="countries-route">
            <div className='route-header'>
                <h1>Countries</h1>
                <a href='../'>
                    <img src={'/logo.png'} />
                </a>
            </div>

            {leagueGroups.map((leagueGroup, i) => {
                return (<LeageGroupComponent key={i}
                    leagueGroup={leagueGroup}
                />);
            })}
        </div>
    );
}