import { Country } from "../country/country"
import { be, by, ca, cz, dk, fi, fr, hu, kz, lt, lv, nl, pl, ro, ru, sk, us } from "../country/country-constants"
import { League } from "./league"
import { belarusianExtraleague, beneliga, czechTipsportExtraliga, czechiaChanceLiga, czechiaSecondLiga, denmarkFirstDivisjon, ersteLiga, ffhgFirstDivision, ffhgSecondDivision, franceMagnusLigueSynerglace, kazakhstanHockeyChampionship, kontinentalHockeyLeague, mestisLiga, metalLigaen, optibetHokejaLiga, polishFirstLiga, polskaHokejLiga, slovakiaSecondLevelSlovenskaHokejovaLiga, slovakiaTiposExtraliga, smLiiga, suomiSaarjaa, vyshayaHokkeinayaLiga } from "./league-constants"

export interface LeagueGroup {
    addendum?: JSX.Element,
    countries: Country[],
    leagues: League[],
}

export const leagueGroups: LeagueGroup[] = [
    {
        countries: [ cz ],
        leagues: [ czechTipsportExtraliga, czechiaChanceLiga, czechiaSecondLiga ]
    },
    {
        countries: [ by ],
        leagues: [ belarusianExtraleague ],
    },
    {
        countries: [ be, nl ],
        leagues: [ beneliga ]
    },
    {
        countries: [ dk ],
        leagues: [ metalLigaen, denmarkFirstDivisjon ]
    },
    {
        addendum: (<b>Partner agent/consultant Mr. Juho Laakso.</b>),
        countries: [ fi ],
        leagues: [ smLiiga, mestisLiga, suomiSaarjaa ]
    },
    {
        countries: [ fr ],
        leagues: [ franceMagnusLigueSynerglace, ffhgFirstDivision, ffhgSecondDivision ]
    },
    {
        countries: [ hu, ro ],
        leagues: [ ersteLiga ]
    },
    {
        countries: [ kz ],
        leagues: [ kazakhstanHockeyChampionship ]
    },
    {
        countries: [ lv ],
        leagues: [ optibetHokejaLiga ]
    },
    {
        countries: [ pl ],
        leagues: [ polskaHokejLiga, polishFirstLiga ]
    },
    {
        countries: [ ru ],
        leagues: [ kontinentalHockeyLeague, vyshayaHokkeinayaLiga ]
    },
    {
        countries: [ sk ],
        leagues: [ slovakiaTiposExtraliga, slovakiaSecondLevelSlovenskaHokejovaLiga ]
    },
    {
        countries: [ us, ca ],
        leagues: [],
        addendum: (<b>Partner agent Mr. Darryl Wolski (2112Hockeyagency).</b>)
    }
]