import { useContext } from 'react';
import './items.css';
import { LanguageContext } from '../../../../context/language-context';

export function Items(): JSX.Element {
    const { t } = useContext(LanguageContext);

    const menuItems = new Map([
        [ t('menu_home'), '#' ],
        [ t('menu_our_services'), '#services' ],
        [ t('menu_our_clients'), '#clients' ],
        [ t('menu_about_us'), '#about-us' ],
        [ t('menu_contact_us'), '#contact-us' ],
    ]);

    return (
        <div className='items'>
            {Array.from(menuItems.keys()).map((key) => {
                const value = menuItems.get(key);
                return (
                    <a
                        className='menu-item'
                        key={key}
                        href={value}
                    >
                        {key}
                    </a>
                );
            })}
        </div>
    );
}