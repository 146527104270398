import './clients-route.css';
import { playerList } from './player-list';
import { PlayerComponent } from './player/player-component';

export function ClientsRoute(): JSX.Element {
    return (
        <div className='players-route'>
            <div className='route-header'>
                <h1>Our players</h1>
                <a href='../'>
                    <img src={'/logo.png'} />
                </a>
            </div>

            {playerList.map((player) => (
                <PlayerComponent player={player} />
            ))}
        </div>
    );
}