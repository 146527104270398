import { cz, sk, ru, lv, de } from "../country/country-constants";
import { Coach } from "./coach";

export const alesTotter: Coach = {
    name: 'Aleš Totter',
    pictureUrl: 'ales-totter',
    bornIn: 1972,
    countries: [ cz, sk ],
    description: 'Aleš is a well experienced professional trainer a hockey is an “alfa and omega” of his daily life. Being fluent in English and Russian he can easily communicate with foreign  players. He spent 2 seasons in České Budějovice as an assistant of a respected NHL hockey player Václav Prospal.',
};

export const adamVarba: Coach = {
    name: 'Adam Vrba',
    pictureUrl: 'adam-vrba',
    bornIn: 1982,
    countries: [ cz ],
    description: 'Adam is a professional goalie coach in Havířov, having experiences from Norway, Latvia, Belgium, Germany a also some profi teams in the Czech republic. He is a favorite trainer for his attitude, being friendly just as strict and and thorough when necessary.',
};

export const alexMikhenotok: Coach = {
    name: 'Alex Mikhenotok',
    pictureUrl: 'alex-mikhenotok',
    countries: [ ru ],
    description: 'In case a good trainer is formed by a rich hockey history, Alex definitely counts into a group of good trainers. Playing in Ukrainian, Russian and… Extra league fulfilled his range of skills by very high quality items. As a trainer he can responsibly lead the players and make them score just as they get to his team.',
};

export const valeryKulibaba: Coach = {
    name: 'Valery Kulibaba',
    pictureUrl: 'valery-kulibaba',
    bornIn: 1972,
    countries: [ lv, ru, de ],
    description: 'This coach got his professional skills in Latvian league MHL (an international professional league) and KHL. To his pros we can definitely count strategical thinking, an ability to motivate his team members and ambitions to evolve the skills of the players.',
};