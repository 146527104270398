import { useContext } from 'react';
import './about-us-person.css';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { LanguageContext } from '../../../context/language-context';

export interface AboutUsPerson {
    name: string,
    position: string,
    url: string,
    description: string,
}

export const petrFormanek: AboutUsPerson = {
    name: 'Petr Formánek',
    position: 'about_us_position_owner',
    url: 'petr-formanek',
    description: 'about_us_petr_bio',
};

export const michalFoltyn: AboutUsPerson = {
    name: 'Michal Foltýn',
    position: 'about_us_position_consultant',
    url: 'michal-foltyn',
    description: 'about_us_michal_bio',
};

interface AboutUsPersonInput {
    person: AboutUsPerson,
}

export function AboutUsPerson({ person }: AboutUsPersonInput): JSX.Element {
    const { t } = useContext(LanguageContext);

    return (
        <div className='about-us-person'>
            <img src={`about-us/${person.url}.png`}></img>

            <div>
                <h3>{person.name},</h3>
                <h5>{t(person.position)}</h5>
                <ReactMarkdown children={t(person.description)} />
            </div>
        </div>
    );
}