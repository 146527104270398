import { Items } from './items/items';
import { Languages } from './languages/languages';
import './menu.css'

export function Menu(): JSX.Element {
    return (
        <div className='menu-main'>
            <img src={'/logo.png'} />
            <div>
                <Items/>
                <Languages/>
            </div>
        </div>
    );
}